import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1279.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
              <path d="M4995 10765 c-178 -13 -241 -21 -495 -62 -388 -62 -891 -208 -1215
-353 -38 -17 -86 -38 -105 -47 -227 -102 -573 -294 -771 -427 -113 -76 -351
-252 -409 -302 -14 -12 -74 -64 -135 -116 -448 -387 -838 -860 -1137 -1383
-401 -699 -640 -1482 -699 -2282 -15 -214 -7 -781 15 -958 41 -342 90 -600
162 -855 184 -652 450 -1206 838 -1745 28 -38 53 -72 56 -75 3 -3 35 -43 70
-90 190 -250 503 -570 831 -845 146 -123 510 -374 702 -484 97 -55 385 -203
477 -244 19 -9 67 -30 105 -47 39 -17 122 -51 185 -75 646 -241 1260 -354
1925 -355 197 0 578 21 625 35 8 2 56 9 105 15 285 34 661 122 1003 237 679
227 1352 624 1887 1112 105 97 341 336 415 421 36 41 67 77 70 80 72 69 328
421 466 640 334 532 581 1155 708 1790 63 315 106 735 106 1050 0 400 -66 934
-162 1305 -113 441 -305 929 -505 1285 -108 190 -122 214 -249 404 -261 394
-552 723 -957 1083 -20 18 -74 63 -119 99 -46 36 -92 74 -103 83 -45 37 -245
178 -354 249 -783 509 -1667 801 -2606 862 -205 13 -514 11 -730 -5z m705
-321 c437 -16 976 -128 1427 -295 213 -79 363 -144 583 -256 379 -193 713
-421 1035 -705 16 -14 41 -36 55 -48 95 -81 321 -315 443 -459 503 -590 851
-1262 1051 -2026 63 -245 88 -382 133 -730 26 -201 26 -848 0 -1045 -9 -74
-22 -171 -28 -215 -36 -278 -143 -704 -251 -995 -221 -596 -526 -1114 -933
-1585 -127 -148 -386 -407 -520 -521 -530 -451 -1102 -770 -1774 -989 -788
-255 -1722 -303 -2556 -129 -279 58 -681 180 -870 264 -22 10 -89 39 -150 65
-345 148 -761 396 -1055 628 -334 264 -658 588 -883 884 -29 38 -55 70 -58 73
-3 3 -42 57 -87 121 -531 757 -845 1634 -912 2549 -13 172 -13 554 0 735 62
893 375 1779 888 2520 53 76 236 317 264 347 7 7 42 47 78 88 53 62 173 191
241 259 51 52 195 185 259 241 41 36 77 68 80 71 18 20 275 213 372 279 242
165 606 367 832 462 533 223 940 330 1506 398 119 14 466 28 585 24 50 -2 160
-6 245 -10z"/>
<path d="M5167 10419 c-242 -10 -526 -42 -707 -79 -478 -97 -899 -239 -1319
-445 -165 -81 -481 -269 -617 -368 -314 -227 -437 -331 -695 -587 -597 -592
-1045 -1359 -1273 -2180 -132 -477 -186 -868 -186 -1360 0 -791 172 -1528 521
-2231 168 -338 337 -606 564 -890 150 -189 203 -248 395 -439 259 -259 441
-411 731 -606 1084 -732 2392 -1010 3694 -784 827 143 1644 518 2305 1059 63
52 117 97 120 100 3 3 28 26 55 50 229 202 548 558 726 811 346 491 598 1016
753 1570 82 293 129 539 163 845 25 230 25 795 0 1025 -92 844 -362 1590 -829
2289 -297 444 -669 844 -1093 1173 -134 104 -377 268 -540 365 -567 336 -1261
571 -1930 652 -144 18 -218 22 -555 35 -52 2 -179 0 -283 -5z m1834 -2183
c282 -47 476 -117 704 -251 168 -99 272 -181 425 -335 124 -124 145 -150 238
-298 141 -225 225 -434 279 -694 26 -123 28 -150 28 -358 -1 -206 -3 -236 -28
-359 -61 -294 -166 -543 -325 -771 -48 -68 -141 -183 -154 -188 -19 -8 -4 -31
44 -73 103 -88 234 -225 260 -274 14 -28 31 -49 38 -47 6 1 9 -1 6 -6 -3 -5
12 -44 34 -88 47 -97 64 -142 98 -264 23 -87 26 -112 26 -280 0 -159 -3 -197
-22 -270 -31 -117 -74 -240 -84 -240 -4 0 -8 -8 -8 -18 0 -44 -160 -275 -255
-366 -133 -128 -318 -239 -498 -297 -174 -57 19 -53 -2712 -56 -1380 -2 -2520
-1 -2532 2 l-23 5 0 605 0 605 423 -2 422 -3 3 -176 2 -176 53 -7 c28 -3 947
-6 2041 -6 2200 0 2053 -4 2161 64 60 39 115 100 147 165 26 54 37 210 19 277
-28 104 -124 205 -241 251 -43 17 -155 18 -2090 23 -2236 6 -2070 2 -2250 62
-173 57 -357 174 -495 315 -71 72 -155 177 -155 193 0 6 -4 10 -9 10 -11 0
-104 189 -130 265 -50 150 -78 350 -67 482 31 378 236 733 545 942 162 111
390 189 605 210 73 7 711 11 1892 11 1494 0 1783 -2 1793 -14 8 -9 10 -130 9
-427 l-3 -414 -1855 -5 -1855 -5 -59 -30 c-75 -38 -136 -100 -181 -186 -32
-61 -35 -74 -35 -150 0 -134 27 -200 122 -298 64 -65 112 -88 215 -102 52 -7
603 -9 1683 -6 1421 3 1614 6 1680 20 329 70 610 279 764 570 92 173 126 317
126 528 0 204 -28 326 -116 503 -82 164 -202 300 -364 408 -161 108 -307 163
-500 188 -59 7 -590 10 -1765 8 l-1680 -3 -3 -183 -2 -182 -423 2 -422 3 -3
608 -2 607 2157 0 2158 0 146 -24z m-2939 -5881 c12 -5 17 -22 20 -63 4 -64 1
-75 -14 -49 -20 37 -69 85 -97 97 -43 17 -85 -4 -89 -46 -4 -36 7 -45 120
-108 112 -61 146 -141 95 -217 -36 -53 -67 -64 -189 -64 -59 0 -111 5 -117 10
-5 6 -12 39 -16 75 l-6 65 19 -25 c85 -107 141 -133 189 -88 48 45 25 81 -98
151 -84 48 -119 91 -119 148 0 49 45 103 98 118 38 10 174 8 204 -4z m2860 4
c42 -11 52 -31 46 -93 l-3 -38 -31 44 c-41 58 -93 84 -132 66 -30 -15 -42 -59
-21 -84 7 -9 45 -33 85 -55 40 -22 87 -56 104 -75 25 -29 30 -43 30 -88 0 -45
-5 -59 -28 -84 -16 -17 -47 -35 -73 -42 -52 -14 -204 -9 -221 8 -14 14 -24
122 -12 122 5 -1 24 -21 42 -46 60 -81 139 -96 170 -31 20 40 -2 68 -93 117
-101 56 -135 96 -135 160 0 82 63 127 180 129 30 0 72 -4 92 -10z m-3698 -13
c43 -18 66 -51 66 -96 0 -43 -5 -51 -52 -82 l-36 -25 37 -17 c56 -24 76 -51
76 -101 0 -32 -6 -50 -24 -69 -40 -42 -95 -50 -334 -47 l-88 1 15 29 c19 36
24 337 7 370 -27 49 -22 51 144 51 111 0 165 -4 189 -14z m486 -51 c0 -36 -3
-65 -7 -65 -3 0 -29 25 -56 56 -47 52 -52 55 -91 52 l-41 -3 0 -90 c0 -105 10
-115 74 -71 20 14 40 26 44 26 4 0 7 -29 7 -65 0 -36 -4 -65 -9 -65 -4 0 -24
14 -42 30 -19 17 -43 29 -54 28 -18 -3 -20 -11 -20 -98 l0 -95 50 -3 c49 -3
52 -2 105 52 30 30 57 53 61 50 7 -4 4 -48 -7 -101 -4 -23 -5 -23 -199 -23
l-196 0 18 33 c14 25 18 63 21 167 3 116 1 143 -16 193 l-20 57 189 0 189 0 0
-65z m850 0 c0 -36 -4 -65 -8 -65 -5 0 -30 25 -56 55 -74 86 -76 83 -76 -119
0 -124 4 -185 14 -214 8 -22 13 -42 11 -44 -2 -1 -49 -2 -104 0 l-101 3 15 36
c20 47 22 393 3 393 -7 0 -36 -26 -65 -57 l-52 -58 -1 68 0 67 210 0 210 0 0
-65z m497 54 c131 -28 200 -150 163 -289 -15 -58 -83 -123 -143 -138 -41 -11
-314 -19 -323 -10 -2 3 3 22 12 43 13 31 15 69 12 196 -2 109 -7 162 -15 171
-7 6 -13 18 -13 25 0 16 235 18 307 2z m423 1 c0 -15 128 -337 156 -392 14
-27 21 -48 17 -49 -7 0 -218 0 -236 1 -4 0 2 17 14 38 16 27 20 46 15 65 -6
26 -8 27 -85 27 -63 0 -80 -3 -85 -16 -11 -30 -6 -53 20 -83 14 -17 21 -31 15
-32 -6 0 -36 0 -66 0 -30 0 -57 0 -60 0 -3 1 7 20 23 44 30 44 47 80 105 233
l34 91 -18 34 c-10 19 -18 37 -19 42 0 4 38 7 85 7 50 0 85 -4 85 -10z m550
-55 c0 -36 -3 -65 -8 -65 -4 0 -23 20 -42 44 -39 49 -66 70 -80 61 -6 -4 -10
-76 -10 -170 0 -129 4 -173 16 -205 9 -23 15 -43 13 -45 -4 -3 -158 -8 -196
-6 -8 1 -6 14 7 44 17 36 20 66 20 199 0 156 -4 188 -22 188 -10 0 -97 -91
-98 -102 0 -5 -4 -8 -10 -8 -6 0 -10 28 -10 65 l0 65 210 0 210 0 0 -65z m315
-128 c41 -103 83 -203 95 -222 12 -19 18 -35 13 -36 -7 0 -218 0 -236 1 -5 0
1 16 13 35 16 25 20 43 15 65 l-7 30 -79 0 c-77 0 -80 -1 -90 -27 -8 -23 -5
-32 21 -65 17 -21 26 -38 20 -39 -40 -2 -120 -1 -122 2 -1 2 10 25 25 50 29
49 112 253 122 301 5 19 1 37 -9 53 -28 42 -22 46 65 43 l81 -3 73 -188z m880
157 c-18 -44 -21 -293 -4 -343 19 -55 104 -78 142 -39 44 44 47 59 47 200 0
120 -3 142 -20 170 -11 18 -20 36 -20 41 0 4 23 7 50 7 28 0 50 -2 50 -5 0 -3
-9 -23 -20 -45 -17 -33 -20 -59 -20 -172 0 -153 -12 -191 -70 -221 -47 -24
-172 -19 -220 9 -67 39 -74 59 -80 227 -3 93 -10 160 -18 174 -6 12 -12 24
-12 27 0 3 47 6 105 6 l105 0 -15 -36z m604 26 c54 -15 81 -47 81 -95 0 -42
-25 -76 -74 -102 -18 -10 -18 -11 5 -18 13 -4 39 -18 57 -32 29 -22 32 -29 32
-77 0 -57 -19 -85 -72 -105 -20 -8 -254 -15 -355 -11 -8 0 -6 13 7 43 16 35
20 65 20 182 0 117 -4 147 -20 183 l-19 42 152 0 c84 0 167 -4 186 -10z"/>
<path d="M3057 2333 c-4 -3 -7 -44 -7 -90 l0 -83 28 0 c44 0 67 33 66 93 0 39
-5 54 -23 69 -23 18 -52 24 -64 11z"/>
<path d="M3057 2123 c-4 -3 -7 -48 -7 -100 l0 -93 26 0 c38 0 61 16 73 51 14
41 14 72 0 102 -15 34 -73 59 -92 40z"/>
<path d="M4930 2135 l0 -205 36 0 c43 0 88 42 98 91 12 63 7 207 -10 249 -18
43 -55 70 -99 70 l-25 0 0 -205z"/>
<path d="M5342 2198 c-28 -63 -47 -127 -39 -134 3 -3 31 -2 62 1 65 9 64 4 21
118 l-24 62 -20 -47z"/>
<path d="M6124 2155 c-18 -47 -28 -88 -23 -91 12 -7 119 6 119 15 0 13 -55
161 -60 161 -3 0 -19 -38 -36 -85z"/>
<path d="M7675 2317 c-3 -12 -5 -54 -3 -92 3 -68 4 -70 28 -67 44 6 65 37 64
92 -1 56 -23 90 -60 90 -16 0 -25 -7 -29 -23z"/>
<path d="M7674 2117 c-3 -8 -4 -52 -2 -98 3 -81 4 -84 28 -87 44 -4 73 29 78
89 4 49 1 57 -23 82 -29 29 -72 36 -81 14z"/>

        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
